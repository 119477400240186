import { Heading, useTheme } from "@aws-amplify/ui-react";

export function LoginHeader() {
  const { tokens } = useTheme();

  return (
    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`} className="heading-style-login">
     Platform Sign-On
    </Heading>
  );
}
