import React, { useState, useEffect } from "react";
import "./navbar.scss";
import { faUser, faSignOut } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageMenu from "../languageMenu/languageMenu";
import { useTranslation } from "react-i18next";

export default function NavBar({ user, signOut }) {
  const { t, i18n } = useTranslation("common");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = () => {
    setIsLoggingOut(true);
    signOut();
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
  }, []);

  return (
    <div className="navbar">
      <img
        alt="Embedded Image"
        src="/header-logo.png"
        className="navbar-logo"
      />
      <div className="nav-container" style={{ paddingTop: "15px" }}>
        <FontAwesomeIcon
          className="nav-icon"
          icon={faUser}
          onClick={toggleDropdown}
        />
        {dropdownOpen && (
          <div className="dropdown-menu fade-in">
            <div className="dropdown-container">
              <div className="user-info">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <div className="user-details">
                  <div className="dropdown-item-username">
                    {user?.attributes?.name}
                  </div>
                  <div className="dropdown-item-email">
                    {user?.attributes?.email}
                  </div>
                </div>
              </div>
            </div>

            <LanguageMenu />

            <div className="dropdown-item" onClick={handleLogout}>
              {isLoggingOut ? (
                <>
                  <div className="loader-container">
                    <div className="loader-navbar"></div>
                    {t("Logout")}
                  </div>
                </>
              ) : (
                <>
                  <FontAwesomeIcon className="logout-icon" icon={faSignOut} />
                  {t("Logout")}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
