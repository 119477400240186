// ---- Amplify Imports ------------------------------------------------------------------
import { Amplify } from "aws-amplify";
import {
  Authenticator,
  ThemeProvider,
} from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
//aws config, need imported I believe
import config from "./aws-exports";
// ---- Library Imports -------------------------------------------------------------------
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// ---- Suncor Dozer App Imports ---------------------------------------------------------
import "./App.scss";
import Home from "./views/home";
import { LoginHeader } from "./components/amplifyLoginPage/header/loginHeader";
import { Header } from "./components/amplifyLoginPage/header/header";
import "./components/amplifyLoginPage/styles_light.scss";
import { LoginFooter } from "./components/amplifyLoginPage/footer/loginFooter";
import { darkModeTheme, lightModeTheme } from "./components/amplifyLoginPage/themes/themes";


// ---- Configure App --------------------------------------------------------------------
Amplify.configure({
  ...config,
  cookieStorage: {
    domain: "smartrview.com",
    path: "/",
    expires: 1, 
    secure: true, // Set to false for localhost
    sameSite: "none", // Lax for local development
  },
  "oauth":{//overwrite redirectSignIn and redirectSignOut to be exact url that be used in sso flow
    ...config.oauth,
    "redirectSignIn": `${process.env.REACT_APP_BASE_URL}/home`,
    "redirectSignOut": `${process.env.REACT_APP_BASE_URL}`
 }
});

//--------------------------------------------------------------------------------------//
//                               Authenticator Components                               //
//--------------------------------------------------------------------------------------//

const formFields = {
  confirmVerifyUser: {
    confirmation_code: {
      labelHidden: false,
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

const components = {
  Header,
  SignIn: {
    Header: LoginHeader,
    Footer: LoginFooter
  }
};

//--------------------------------------------------------------------------------------//
//                                         HTML                                         //
//--------------------------------------------------------------------------------------//

export default function App() {

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap" rel="stylesheet"></link>
      <ThemeProvider theme={lightModeTheme}>
      <div>
        <Authenticator formFields={formFields} components={components} hideSignUp={true}  className="custom-authenticator-style">
          {({ signOut, user }) => (
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path="/home" element={<Home user={user} signOut={signOut} />} />
              </Routes>
            </BrowserRouter>
          )}
        </Authenticator>
      </div>
      </ThemeProvider>
    </>
  );
}
