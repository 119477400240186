import React from 'react';
import './appSelection.scss';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";

export default function AppSelection({ url, name, valid, image, information }) {

	const [t, i18n] = useTranslation("common");
    function handleClick() {
        if (valid) {
            window.open(url, "_blank");
        }
    }

    return (
        <>
            {valid &&
                (<div className="appSelectionContainer" onClick={handleClick}>
                    <div className="appSelection">
                        <Container>
                            <Row>
                                <Col>
                                    <img src={`${image}.png`} alt={name} className="appSelectionImage" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='appSelectionName light-mode'>
                                        {name}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='appSelectionInfo'>
                                        {information}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>)}
            {!valid &&
                <Tooltip title={t('no-access-message-temp')} placement="bottom">
                    <div className="appSelectionContainerGrey">
                        <div className="appSelectionGrey">
                            <Container>
                                <Row>
                                    <Col>
                                        <img src={`${image}.png`} alt={name} className="appSelectionImage greyed-out" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='appSelectionName'>
                                            {name}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='appSelectionInfo'>
                                            {information}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Tooltip>}
        </>
    );
}
