import React, { useState } from "react";
import {
  Flex,
  Link,
  useAuthenticator,
  useTheme,
  Divider,
  Button,
  Image,
  Heading,
  Alert,
} from "@aws-amplify/ui-react";
import { Modal } from "react-bootstrap";
import { Auth } from "aws-amplify";
import ssoProviders from "../../../utilities/ssoProviders";

export function LoginFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const openModal = () => {
    setErrorMessage();
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  function handleLegacy() {
    window.open("https://legacy.smartrview.com/", "_self");
  }

  const handleSSOSubmit = () => {
    const companyNameWithoutSpace = companyName.trim().toLowerCase().replace(/\s+/g, '');
    const provider = ssoProviders[companyNameWithoutSpace];
  
    if (provider) {
      try {
        Auth.federatedSignIn({ provider });
      } catch (error) {
        console.error(`Fail to login through ${provider}`);
      }
    } else {
      setErrorMessage("Not Support SSO for this company.");
    }
  };

  const dismissErrorMessage = () => {
    setErrorMessage();
  };

  const modalBodyStyle = {
    padding: "2.0rem",
    flexDirection: "column",
  };

  return (
    <>
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
        <Link onClick={handleLegacy}>Trouble logging in? Try accessing our legacy portal</Link>
      </Flex>
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
        <Link onClick={toResetPassword}>Reset Password</Link>
        <Divider orientation="vertical" />
        <Link onClick={openModal}>SSO</Link>
      </Flex>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Image
          className="amplify-image header-icon-login"
          alt="Embedded Image"
          src="/header-logo.png"
        />
        <fieldset className="amplify-flex" style={modalBodyStyle}>
          <Heading level={3}>SSO Sign In</Heading>
          <Flex justifyContent="center" direction="column">
            <div className="amplify-flex amplify-field amplify-textfield">
              <label className="amplify-label">Company name</label>
              <input
                className="amplify-input"
                type="text"
                placeholder="Enter company name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </Flex>
          <Flex justifyContent="center" direction="column">
            <Button
              backgroundColor={"#0a235a"}
              color={"white"}
              border="none"
              borderRadius={"3px"}
              onClick={handleSSOSubmit}
            >
              Submit
            </Button>
            <Link
              onClick={closeModal}
              style={{ textAlign: "center", fontSize: "0.9rem" }}
            >
              Back to Sign In
            </Link>
          </Flex>
          {errorMessage && (
            <Alert
              variation="error"
              onDismiss={dismissErrorMessage}
              isDismissible={true}
            >
              {errorMessage}
            </Alert>
          )}
        </fieldset>
      </Modal>
    </>
  );
}
