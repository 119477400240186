/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_gnD3h4UFh",
    "aws_user_pools_web_client_id": "4r1k9h2vq54vaqbc79njcmamq9",
    "oauth":{
        "domain":"smartrview.auth.us-east-1.amazoncognito.com",
        "scope":[
            "email", "openid", "phone", "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://hub.smartrview.com/home",
        "redirectSignOut": "https://hub.smartrview.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "cookieStorage": {
        "domain": "smartrview.com",  // Adjust this to your actual domain in production
        "path": "/",
        "expires": 1,
        "secure": true,  // Set to true if using HTTPS
        "sameSite": "none"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

export default awsmobile;
