import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { getAppText } from '../utilities/utils';
import './home.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

import AppSelection from '../components/appSelection/appSelection';
import NavBar from '../components/navbar/navbar';
import urls from '../utilities/urls';

import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Home({ user, signOut }) {
    // console.log("user info", user)
    const { t, i18n } = useTranslation("common");
    const [userGroups, setUserGroups] = useState(user.signInUserSession.idToken.payload['cognito:groups']);
    const [isDev, setIsDev] = useState(false);

    const AppText = getAppText(t);


    const isAdmin = userGroups.includes('Cascadia_Admin');

    const handleToggleChange = () => {
        if (isAdmin) {
            setIsDev(!isDev);
        }
    };

    const environmentUrls = isDev ? urls.dev : urls.prod;

    const appendDevToName = (name) => isDev ? `${name} (DEV)` : name;

    // Define your apps
    const apps = [
        {
            name: appendDevToName("SmartRView"),
            url: environmentUrls.SMARTRVIEW_URL,
            image: "/smartrview-logo",
            information: AppText.SMARTRVIEW,
            valid: userGroups.includes("Portal")
        },
        {
            name: appendDevToName("Operator Coach"),
            url: environmentUrls.MLCOACH_URL,
            image: "/operator-coach-logo",
            information: AppText.MLCOACH,
            valid: userGroups.includes("ML_Coach")
        },
        {
            name: appendDevToName("Terrain Coach"),
            url: environmentUrls.TERRAINCOACH_URL,
            valid: userGroups.includes("Terrain_Coach"),
            image: "/terrain-coach-logo",
            information: AppText.TERRAINCOACH,
        },
        {
            name: appendDevToName("Taurus"),
            url: environmentUrls.TAURUS_URL,
            valid: userGroups.includes("Dozer_App"),
            image: "/taurus-logo",
            information: AppText.TAURUS,
        },
        // Conditionally add the Field Service App only if they have access to it
        ...(userGroups.includes("Field_Service_Admin") || userGroups.includes("Field_Service_Managers") || userGroups.includes("Field_Service_Users"))
            ? [{
                name: appendDevToName("Field Service App"),
                url: environmentUrls.FIELDSERVICEAPP_URL,
                valid: true,
                image: "/cascadia-scientific-mountain-logo",
                information: AppText.FIELDSERVICEAPP,
            }]
            : []
    ];


    //use this when all apps hit prod
    // const sortedApps = apps.sort((a, b) => b.valid - a.valid);

    //use this temporarily
    const sortedApps = apps.sort((a, b) => {
        if (a.valid === b.valid) return 0;
        if (a.valid) return -1;
        return 1;
    });


    return (
        <div className='index'>
            <NavBar user={user} signOut={signOut} />
            <Box display="flex" justifyContent="center" alignItems="center" mt={4} className="info-message-container">
                <FontAwesomeIcon icon={faCircleInfo} className="user-icon" style={{color: '#ababab', marginRight: '5px', fontSize:'14px'}} />
                <Typography style={{ marginRight: '3px', color: '#676767', fontSize: '12px' }}>
                    {t("homepagemessage")}
                </Typography>
            </Box>
            {isAdmin && (
                <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                    <Typography variant="body1" style={{ marginRight: '3px', color: 'black' }}>
                        Production
                    </Typography>
                    <Switch
                        checked={isDev}
                        onChange={handleToggleChange}
                        name="environmentToggle"
                        color="primary"
                    />
                    <Typography variant="body1" style={{ marginLeft: '3px', color: 'black' }}>
                        Develop
                    </Typography>
                </Box>
            )}
            <div className='container'>
                <Container>
                    <Row>
                        {sortedApps.map((app, index) => (
                            <Col md={4} key={index}>
                                <AppSelection
                                    name={app.name}
                                    url={app.url}
                                    valid={app.valid}
                                    image={app.image}
                                    information={app.information}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    );
}
