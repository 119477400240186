import React, { useContext, useState, useEffect } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./style.scss";
//import { UserContext } from "../../context/userState";

// const languages = [
// 	{ value: "English" },
// 	{ value: "Português" },
// 	{ value: "Español" },
// ];

export default function LanguageMenu() {
	const [t, i18n] = useTranslation("common");
	//const { addLanguage } = useContext(UserContext);

	useEffect(() => {
		let lang = window.localStorage.getItem("csLanguage");
		if (lang === null) updateLanguage("en");
		else {
			lang = JSON.parse(lang).language;
			updateLanguage(lang);
		}
	}, []);

	function updateLanguage(lang) {
		i18n.changeLanguage(lang);
		//addLanguage(lang);
		window.localStorage.setItem(
			"csLanguage",
			JSON.stringify({ language: lang })
		);
	}

	return (
		<div className="language-menu">
			<div className="dropdown">
				<a
					className="dropdown-item pointer-cursor"
					data-toggle="dropdown"
					href="#languageMenu"
					role="button"
					aria-haspopup="true"
					aria-expanded="true"
					aria-controls="#dataMenu"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					{i18n.language}{" "}
					<span>
						<FontAwesomeIcon icon={faChevronDown} size="xs"/>
					</span>
				</a>

				<div
					className="dropdown-menu"
					id="languageMenu"
					style={{ right: "100%", top: 0 }}
				>
					<a
						className="dropdown-item pointer-cursor"
						href="#"
						onClick={() => {
							updateLanguage("en");
						}}
					>
						English
					</a>
					<a
						className="dropdown-item pointer-cursor"
						href="#"
						onClick={() => {
							updateLanguage("pt");
						}}
					>
						Português
					</a>
					<a
						className="dropdown-item pointer-cursor"
						href="#"
						onClick={() => {
							updateLanguage("es");
						}}
					>
						Español
					</a>
				</div>
			</div>
		</div>
	);
}
