import { Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    // If you see <Image> anywhere else in the code, i replace it with <img> tag because of the weird styling behaviour that <image> from amplify lib can have
    <Image alt="Embedded Image" src="/header-logo.png" className="header-icon-login" />
  );
}
