const urls = {
    prod: {
        TAURUS_URL: "https://taurus.smartrview.com/",
        SMARTRVIEW_URL: "https://portal.smartrview.com/",
        MLCOACH_URL: "https://mlcoach.smartrview.com/",
        TERRAINCOACH_URL: "https://terraincoach.smartrview.com/",
        FIELDSERVICEAPP_URL: "https://field.smartrview.com/",
    },
    dev: {
        TAURUS_URL: "https://taurus-beta.smartrview.com/",
        SMARTRVIEW_URL: "https://staging.smartrview.com/",
        MLCOACH_URL: "https://mlcoach-dev.smartrview.com/",
        TERRAINCOACH_URL: "https://terraincoach-beta.smartrview.com/",
        FIELDSERVICEAPP_URL: "https://beta-field.smartrview.com/",
    }
};

export default urls;
