import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";

import common_es from "./locales/es/common.json";
import common_en from "./locales/en/common.json";
import common_pt from "./locales/pt/common.json";
//import common_pt from "./locales/pt/common.json";

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
}


i18n
    //   // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    //   // learn more: https://github.com/i18next/i18next-http-backend
    //   .use(Backend)
    //   // detect user language
    //   // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //   .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(detector)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({

        detection: DETECTION_OPTIONS,
        resources: {
            en: {
                common: common_en               // 'common' is our custom namespace
            },
            es: {
                common: common_es
            },
            pt: {
                common: common_pt
            },
        },

        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;