export const getAppText = (t) => ({
    TAURUS: (
        <ul>
            <li>{t('TAURUS.item1')}</li>
            <li>{t('TAURUS.item2')}</li>
            <li>{t('TAURUS.item3')}</li>
            <li>{t('TAURUS.item4')}</li>
        </ul>
    ),
    SMARTRVIEW: (
        <ul>
            <li>{t('SMARTRVIEW.item1')}</li>
            <li>{t('SMARTRVIEW.item2')}</li>
            <li>{t('SMARTRVIEW.item3')}</li> 
            <li>{t('SMARTRVIEW.item4')}</li>
        </ul>
    ),
    MLCOACH: (
        <ul>
            <li>{t('MLCOACH.item1')}</li>
            <li>{t('MLCOACH.item2')}</li>
            <li>{t('MLCOACH.item3')}</li>
        </ul>
    ),
    TERRAINCOACH: (
        <ul>
            <li>{t('TERRAINCOACH.item1')}</li>
            <li>{t('TERRAINCOACH.item2')}</li>
            <li>{t('TERRAINCOACH.item3')}</li>
        </ul>
    ),
    FIELDSERVICEAPP: (
        <ul>
            <li>{t('FIELDSERVICEAPP.item1')}</li>
            <li>{t('FIELDSERVICEAPP.item2')}</li>
            <li>{t('FIELDSERVICEAPP.item3')}</li>
            <li>{t('FIELDSERVICEAPP.item4')}</li>
        </ul>
    ),
});
