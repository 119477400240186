import {
    createTheme,
  } from "@aws-amplify/ui-react";


const darkModeTheme = createTheme({
    name: 'dark-theme',
    tokens: {
      colors: {
        background: {
          primary: { value: '#293447' }, // color-background-1
          secondary: { value: '#293447' }, // color-background-3
          tertiary: { value: 'rgb(238, 238, 238)' }, // color-background-2
          quaternary: { value: '#090d14' }, // color-background-4
          quinary: { value: '#152238' }, // color-background-5
          senary: { value: '#203354' }, // color-background-6
        },
        font: {
          primary: { value: '#eef0f2' }, // color-text
          secondary: { value: 'rgb(206, 206, 206)' }, // color-text-alt
        },
        outline: {
          default: { value: 'rgba(135, 135, 135, 0.378)' }, // color-outline
          alert: { value: 'rgba(255, 211, 92, 0.795)' }, // color-outline-alt
        },
        status: {
          success: { value: 'rgb(20, 255, 51)' }, // color-success
          warning: { value: 'rgb(218, 167, 57)' }, // color-warning
          error: { value: 'rgb(255, 37, 37)' }, // color-error
        },
        hover: {
          text: { value: '#000000' }, // color-hover-text
          background: { value: '#c5c5c5' }, // color-hover-background
        },
        neutral: {
          darker: { value: '#1e2d3b' }, // color-1
          dark: { value: '#1f2e47' }, // color-2
          medium: { value: '#1d2f4e' }, // color-3
          light: { value: '#2c3b55' }, // color-4
        },
        greyscale: {
          dark: { value: '#aaaaaa' }, // color-1-grey
          medium: { value: '#bbbbbb' }, // color-2-grey
          light: { value: '#cccccc' }, // color-3-grey
          lighter: { value: '#dddddd' }, // color-4-grey
        }
      },
      fontSizes: {
        body: { value: '16px' }, // $body-font-size
        small: { value: '14px' }, // $small-font-size
        mobileBody: { value: '12px' }, // $mobile-body-font-size
        heading: { value: '18px' }, // $heading-font-size
        mobileHeading: { value: '14px' }, // $mobile-heading-font-size
      },
      space: {
        none: { value: '0' },
        small: { value: '8px' },
        medium: { value: '16px' },
        large: { value: '24px' },
      },
      radii: {
        small: { value: '4px' },
        medium: { value: '8px' },
      }
    },
  });


  const lightModeTheme = createTheme({
    name: 'light-theme',
    tokens: {
      colors: {
        background: {
          primary: { value: '#fffff' }, // color-background-1
          secondary: { value: '#293447' }, // color-background-3
          tertiary: { value: 'rgb(238, 238, 238)' }, // color-background-2
          quaternary: { value: '#090d14' }, // color-background-4
          quinary: { value: '#152238' }, // color-background-5
          senary: { value: '#203354' }, // color-background-6
        },
        font: {
          primary: { value: '#eef0f2' }, // color-text
          secondary: { value: 'rgb(206, 206, 206)' }, // color-text-alt
        },
        outline: {
          default: { value: 'rgba(135, 135, 135, 0.378)' }, // color-outline
          alert: { value: 'rgba(255, 211, 92, 0.795)' }, // color-outline-alt
        },
        status: {
          success: { value: 'rgb(20, 255, 51)' }, // color-success
          warning: { value: 'rgb(218, 167, 57)' }, // color-warning
          error: { value: 'rgb(255, 37, 37)' }, // color-error
        },
        hover: {
          text: { value: '#000000' }, // color-hover-text
          background: { value: '#c5c5c5' }, // color-hover-background
        },
        neutral: {
          darker: { value: '#1e2d3b' }, // color-1
          dark: { value: '#1f2e47' }, // color-2
          medium: { value: '#1d2f4e' }, // color-3
          light: { value: '#2c3b55' }, // color-4
        },
        greyscale: {
          dark: { value: '#aaaaaa' }, // color-1-grey
          medium: { value: '#bbbbbb' }, // color-2-grey
          light: { value: '#cccccc' }, // color-3-grey
          lighter: { value: '#dddddd' }, // color-4-grey
        }
      },
      fontSizes: {
        body: { value: '16px' }, // $body-font-size
        small: { value: '14px' }, // $small-font-size
        mobileBody: { value: '12px' }, // $mobile-body-font-size
        heading: { value: '18px' }, // $heading-font-size
        mobileHeading: { value: '14px' }, // $mobile-heading-font-size
      },
      space: {
        none: { value: '0' },
        small: { value: '8px' },
        medium: { value: '16px' },
        large: { value: '24px' },
      },
      radii: {
        small: { value: '4px' },
        medium: { value: '8px' },
      }
    },
  });


  export { lightModeTheme, darkModeTheme
    
   };